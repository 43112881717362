/* eslint-disable */

(function (d, s, i, r) {
  if (d.getElementById(i)) {
    return;
  }
  var n = d.createElement(s),
    e = d.getElementsByTagName(s)[0];
  n.id = i;
  n.src =
    '//js.hs-analytics.net/analytics/' +
    Math.ceil(new Date() / r) * r +
    '/53.js';
  e.parentNode.insertBefore(n, e);

  // check consent cookie
  const consentCookie = document.cookie
    .split(';')
    .filter((cookie) => cookie.indexOf('__hs_opt_out=yes') > -1);
  const hasOptedOut = consentCookie.length > 0;
  if (hasOptedOut) return;

  window._hsq = window._hsq || [];
  // listen to consent message
  window._hsq.push([
    'addPrivacyConsentListener',
    (consent) => {
      if (consent.allowed) {
        // run hotjar
        addHotJar(
          window,
          document,
          'https://static.hotjar.com/c/hotjar-',
          '.js?sv='
        );
      }
    },
  ]);
})(document, 'script', 'hs-analytics', 300000);
